import React, {useEffect} from 'react';
import Layout from '../components/layout/layout';
import './style.css';
import 'animate.css'

import AOS from 'aos';
import 'aos/dist/aos.css';

import Img_portfolio_01 from "./img/z4png.png";
import NewWheels from "./img/newwheels.png";
import TheaterZone from "./img/theaterzone.png";
import Html_img from "./img/html5.svg";
import Css_img from "./img/css3-alt.svg";
import Js_img from "./img/js.svg";
import Bootstrap_img from "./img/bootstrap.svg";
import Nodejs_img from "./img/node-js.svg";
import Database_img from "./img/database-solid.svg";
import React_img from "./img/react.svg";
import Github_img from "./img/github.svg";

import { Tilt } from 'react-tilt';
const defaultOptions = {
	reverse:        false,  // reverse the tilt direction
	max:            20,     // max tilt rotation (degrees)
	perspective:    1000,   // Transform perspective, the lower the more extreme the tilt gets.
	scale:          1.065,    // 2 = 200%, 1.5 = 150%, etc..
	speed:          1000,   // Speed of the enter/exit transition
	transition:     true,   // Set a transition on enter/exit.
	axis:           null,   // What axis should be disabled. Can be X or Y.
	reset:          true,    // If the tilt effect has to be reset on exit.
	easing:         "cubic-bezier(.03,.98,.52,.99)",    // Easing on enter/exit.
}

setTimeout(function() {
  document.getElementById("intro").style.display = "none";
}, 2500);



const Home = () => {
  useEffect(() => {
    AOS.init({duration:"1000"});
  }, [])

return(

<Layout><br />
   <div className='container mt-5'>
     <div className='row'>
       <div className='col-lg-4'>
          <Tilt options={defaultOptions}>
             <div className='portfolio_photo mt-3 m-auto'>
               <img src={Img_portfolio_01} alt="profile_photo" />
             </div>
          </Tilt>
       </div>

       <div className='col-lg-8 name'> <br/>
            <h1># Shubham Narayan</h1>
            <h6 className='text-end'><i># FrontEnd & BackEnd Developer</i></h6>
            {/* <p></p> */}
            <p className='text-center'>Hi, Myself Shubham I am currently living in Capital of India .I had completed my Higher Education From CBSE & currently pursuing Graduation From Indira Gandhi University. I had keen interest in WebDesigning and Development during my school days and after doing little reasearch I got to know about Fullstack web development. So, I started my career in it. </p>
            <p className='text-center'>I like to do things creatively & efficiently and this is the rule I apply in my profession too, <br /> which give solid results !</p>
       </div>
     </div> 
   </div>

{/* --------------Recent Work ------------------- */}

<div className="container mt-5">
  <div className="row">

    <div className="col-lg-4 languages "  data-aos="fade-up" data-aos-offset="64" data-aos-easing="ease-in-sine">
      <h1 className='text-center'>Recent Work {'>>'}</h1>
      <p className='text-center m-0 p-0'>
      Here are several projects crafted <br />utilizing these Languases
      </p>
      <ul className='ps-5 ms-2 mt-4'>
        <li data-aos="fade-up" data-aos-offset="50" data-aos-easing="ease-in-sine"><img src={Html_img} alt="html-5" /> HTML 5  </li>
        <li data-aos="fade-up" data-aos-offset="50" data-aos-easing="ease-in-sine"><img src={Css_img} alt="css-3" /> CSS 3</li>
        <li data-aos="fade-up" data-aos-offset="50" data-aos-easing="ease-in-sine"><img src={Bootstrap_img} width={30} alt="bootstrap" /> Bootstrap 5</li>
        <li data-aos="fade-up" data-aos-offset="50" data-aos-easing="ease-in-sine"><img src={Js_img} alt="js" /> JavaScript</li>
        <li data-aos="fade-up" data-aos-offset="50" data-aos-easing="ease-in-sine"><img src={Nodejs_img} alt="nodejs" /> NodeJs</li>
        <li data-aos="fade-up" data-aos-offset="50" data-aos-easing="ease-in-sine"><img src={Nodejs_img} alt="Express" /> ExpressJs</li>
        <li data-aos="fade-up" data-aos-offset="50" data-aos-easing="ease-in-sine"><img src={Database_img} width={25} alt="mySQL" /> MySQL</li>
        <li data-aos="fade-up" data-aos-offset="50" data-aos-easing="ease-in-sine"><img src={Database_img} width={25} alt="mongodb" /> MongoDB</li>
        <li data-aos="fade-up" data-aos-offset="50" data-aos-easing="ease-in-sine"><img src={React_img} alt="react" /> ReactJS</li>
        <li data-aos="fade-up" data-aos-offset="50" data-aos-easing="ease-in-sine"><img src={Github_img} alt="github" /> GitHub</li>
      </ul>

      <div className='contact-info mt-5 p-4' data-aos="fade-right" data-aos-offset="80" data-aos-easing="ease-in-sine">
          <h2 className='mt-3'>Contact Info</h2>
          <em><b>Email Me :</b><a href="mailto:Kanwalisn@gmail.com"> Kanwalisn@gmail.com</a></em><br />
          <em><b>Mobile No:</b><a href="tel:+917419009010"> 7419009010</a></em>
      </div>

    </div>


<div className="col-lg-8 row p-2 m-0 projects">

{/* ---------------New Wheels------------------- */}
{/* <hr/> */}
    <div className='blur-background row p-1 m-0 ' data-aos="fade-up ">
      <div className='col-lg-6 '>
        <div className='project-title'><h4 className='mt-2'>#New Wheels</h4></div>
        <p className='mt-3'>
          In "New_Wheels"  you'll be greeted with a sleek and dynamic design that showcases the hottest cars of the moment. Using HTML 5, CSS 3, Bootstrap 5, we've ensured a responsive layout, meaning you can access site seamlessly across all devices, from desktops to smartphones. 
        </p>
      </div>
      <div className="col-lg-6 p-3">
      <Tilt options={defaultOptions}>
        <a href="#">
          <img className='newwheels-img' src={NewWheels} alt="New Wheels Project Pic" />
        </a>
      </Tilt>
      </div>
    </div>
{/* ---------------New Wheels Ens's------------------- */}

{/* ---------------Theater Zone------------------- */}
{/* <hr className='mt-3' /> */}
    <div className='blur-background row p-1 m-0 mt-3' data-aos="fade-up">
      <div className='col-lg-6 mt-2'>
        <h4 className='mt-2'># Theater Zone</h4>
        <p className='mt-3'>
        The homepage welcomes users with a visually appealing layout showcasing the latest movies, upcoming releases, and popular genres. Users can easily navigate through the site to explore movies, theaters, and showtimes  
        </p>
      </div>

      <div className="col-lg-6 p-3">
       <Tilt options={defaultOptions}>
          <a href="#">
            <img className='newwheels-img' src={TheaterZone} alt="New Wheels Project Pic" />
          </a>
        </Tilt>
      </div>
    </div>
{/* ---------------Theater Zone End's------------------- */}

{/* ---------------Bags Ecomm------------------- */}
{/* <hr className='mt-3' /> */}
    <div className='blur-background row p-1 m-0 mt-3' data-aos="fade-up">
      <div className='col-lg-6'>
        <h4 className='mt-2'># Viva Bags</h4>
        <p className='mt-3'>
        Bagify is a premium ecommerce platform specializing in offering a wide range of bags for every need and style. Powered by the MERN (MongoDB, Express.js, React.js, Node.js) stack, Bagify provides a seamless and immersive shopping experience, combining a sleek user interface with robust backend functionality.
        </p>
      </div>

      <div className="col-lg-6 p-3">
      <Tilt options={defaultOptions}>
        <a href="#">
          <img className='newwheels-img' src={NewWheels} alt="New Wheels Project Pic" />
        </a>
      </Tilt>
      </div>
    </div>
{/* ---------------Bags Ecomm End's------------------- */}

    </div>
    </div>
</div>
{/* --------------Recent Work End's------------------- */}
<div className='contact-info-2 text-center mt-2 p-4' data-aos="fade-right" data-aos-offset="80" data-aos-easing="ease-in-sine">
          <h2 className='mt-3'>Contact Info</h2>
          <em><b>Email Me :</b><a href="mailto:Kanwalisn@gmail.com"> Kanwalisn@gmail.com</a></em><br />
          <em><b>Mobile No:</b><a href="tel:+917419009010"> 7419009010</a></em>
      </div>
<br />

<div id='intro' className='intro text-center fixed-top'>
  <h2>Shubham Narayan </h2>
  <i className='intro-text2'> Portfolio</i>
</div>

</Layout>

)
}

export default Home;