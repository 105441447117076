import React from "react";
import Header from "./header";
import Footer from "./footer"
import {Helmet} from "react-helmet";
import {Toaster} from "react-hot-toast";


const Layout=({children, title , description, keywords, author})=> {
    return(
     <>
        
        <Helmet>
            <meta charSet="utf-8" />
            <meta name="description" content={description}/>
            <meta name="keywords" content={keywords}/>
            <meta name="author" content={author}/>
            <title>{title}</title>
        </Helmet>


        <Header />
        <main style={{ minHeight: "70vh" }}>
            <Toaster />
        {children}  
        </main>
        <Footer />
     </>
    )
}


Layout.defaultProps = {
    title: "Portfolio-01",
    description: "Mern Stack Project",
    keywords: "Mern,React,Node,Mongodb",
    author: "anuKatoch",
};
export default Layout;