import React from "react";
import "./style.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Header=()=> {
    return (
<>
  <div className="container fixed-top">
    <div class="row px-4 mt-2">
        <span class="row nav-btns"> 
          <ul className="text-center mt-2">
            <li>
              <a href="#">Home</a>   
            </li>
            <li>
              <a href="#">Experince</a>    
            </li>
            <li>
              <a href="#">Contact</a>    
            </li>
          </ul>       
        </span>

    </div>
   </div>
</>
 )
}

export default Header;