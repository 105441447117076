import { Routes, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";



import Home from "./pages/home";
import NotFound from "./components/layout/notfound";
const App=()=> {
  return(
    <>
    <Routes>
      <Route path="/" element={<Home />}></Route>


      <Route path="*" element={<NotFound/>}></Route>
    </Routes>
    </>
  )
}

export default App;